import toast from "react-hot-toast";
import Remote from "../services/network";

export const getCountries = async () => {
  try {
    const data = await Remote.get("/countries/");
    return data?.data?.data;
  } catch (e) {}
};

export const getSingleCountry = async (id) => {
  try {
    const data = await Remote.get("/countries/" + id);
    return data?.data?.data;
  } catch (e) {}
};

export const addCountry = async (reqBody) => {
  try {
    const data = await Remote.post("/countries", reqBody);
    toast.success("Country Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateCountry = async (id, reqBody) => {
  try {
    const data = await Remote.post("/countries/" + id + "/update", reqBody);
    toast.success("Country Updated Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteCountry = async (id) => {
  try {
    const data = await Remote.post("/countries/" + id + "/delete");
    toast.success("Country Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
