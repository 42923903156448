import { useState, useEffect } from "react";

import TextField from "../../../features/ui/elements/TextField";
import { RadioButton } from "../../../features/ui/elements/RadioButton";
import SelectField from "../../../features/ui/elements/SelectField";
import {
  addPlan,
  assignFeatures,
  getAllFeatures,
  getSinglePlan,
  updatePlan,
} from "../../../api/plans";
import Button from "../../../features/ui/elements/Button";
import { RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import ModalComponent from "../../../features/ui/elements/ModalComponent";
import { toast } from "react-hot-toast";

const AddPlan = () => {
  const [plan, setPlan] = useState({
    id: null,
    name: "",
    yearlyPrice: null,
    monthlyPrice: null,
    isDefault: false,
    isRecommended: false,
  });
  const [features, setFeatures] = useState();
  const [assignedFeatures, setAssignedFeatures] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
    status: false,
    id: null,
  });

  const { state } = useLocation();
  const { id } = state;

  const fetchData = async () => {
    const data = await getAllFeatures();
    const tempFeatures = data?.map((feature) => ({
      label: feature.name,
      value: feature.id,
      type: feature?.type,
    }));
    setFeatures(tempFeatures);

    if (id) {
      const tempPlan = await getSinglePlan(id);
      if (tempPlan) {
        const clone = (({ planFeatures, ...plan }) => plan)(tempPlan);
        setPlan(clone);

        const tempAssignedFeatures = tempPlan?.planFeatures?.map((feature) => {
          return {
            feature: {
              featureId: feature?.id,
              name: feature?.name,
              type: feature?.type,
            },
            value: feature?.value,
          };
        });
        setAssignedFeatures(tempAssignedFeatures);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    console.log("idd ", e.target.id, " plan ", plan[e.target.id]);
    const id = e.target.id;
    if (id === "isDefault" || id === "isRecommended") {
      setPlan({
        ...plan,
        [e.target.id]: !plan[e.target.id],
      });
    } else {
      const value = e.target.value;
      setPlan({
        ...plan,
        [e.target.id]: value,
      });
    }
  };

  const handleAddPlan = async () => {
    const data = id ? await updatePlan(id, plan) : await addPlan(plan);

    if (data) {
      setPlan({ ...plan, id: data?.id });
    }
  };

  const handleAssignFeatures = async () => {
    if (plan?.id) {
      const reqBody = {
        features: assignedFeatures.map((item) => {
          return {
            featureId: item?.feature?.featureId,
            value: item?.value,
          };
        }),
      };
      await assignFeatures(plan?.id, reqBody);
    } else {
      toast.error("Add Plan Details First");
    }
  };

  const handleDeleteFeature = (id) =>
    setIsDeleteModalOpen({ status: !isDeleteModalOpen?.status, id });

  const handleSubmitDelete = () => {
    const data = [...assignedFeatures];

    if (data) {
      const dataAfterDeleted = data.filter(
        (item) => item?.feature?.featureId !== isDeleteModalOpen?.id
      );
      console.log("dataaa ", dataAfterDeleted);
      setAssignedFeatures(dataAfterDeleted);
      handleDeleteFeature(null);
    }
  };

  return (
    <div className="grid gap-4 p-4">
      <h2 className="text-2xl font-extrabold dark:text-darkModeText">
        {id ? "Edit" : "Add"} Plan
      </h2>
      <div className="space-y-5">
        <div className="grid gap-3 border-[1px] p-3 bg-secondary-100/10">
          <div className="text-sm font-semibold">Plan Data</div>
          <div>
            <TextField
              id="name"
              placeholder="Name"
              value={plan?.name}
              onChange={handleChange}
              name="name"
            />
          </div>
          {console.log("id ", id)}
          <div className="grid gap-3 grid-cols-2">
            <TextField
              id="monthlyPrice"
              placeholder="Monthly Price"
              value={plan?.monthlyPrice}
              onChange={handleChange}
              name="monthlyPrice"
            />
            <TextField
              id="yearlyPrice"
              placeholder="Yearly Name"
              value={plan?.yearlyPrice}
              onChange={handleChange}
              name="yearlyPrice"
            />
          </div>
          <div className="grid gap-3 pl-1 grid-cols-2 text-xs">
            <div className="flex items-center gap-3">
              <div className="space-x-2">
                <span>Is Default</span>
              </div>
              <div>
                <RadioButton
                  id="isDefault"
                  boolValue={plan?.isDefault}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="space-x-2">
                <span>Is Recommended</span>
              </div>
              <div>
                <RadioButton
                  id="isRecommended"
                  boolValue={plan?.isRecommended}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button type="primary" className="w-full" onClick={handleAddPlan}>
              {id ? "Update" : "Add"} Plan
            </Button>
          </div>
        </div>
        <div className="grid gap-6 border-[1px] p-4 bg-secondary-100/10">
          <div className="text-sm font-semibold">Features</div>
          <div>
            <SelectField
              name="features"
              options={features}
              // value={selectedFeature?.value}
              onChange={(e) => {
                const data = [...assignedFeatures];
                const ifExists = data?.find(
                  (item) => item?.feature?.featureId === e.target.value
                );
                console.log("Eee ", e.target.itemName);
                if (!ifExists) {
                  data.push({
                    feature: {
                      featureId: e.target.value,
                      name: e.target.id,
                      type: features?.find(
                        (item) => item?.value === e.target.value
                      ).type,
                    },
                    value: "1",
                  });
                  setAssignedFeatures(data);
                }
              }}
              placeholder={"Features"}
            />
          </div>
          <div className="overflow-auto">
            {assignedFeatures?.length ? (
              <table className="table-auto w-full whitespace-nowrap">
                <thead className=" p-3 rounded-full">
                  <tr className="[&>*]:p-3 text-xs [&>*]:font-medium tracking-wide text-[#033155] dark:text-darkModeText">
                    <th className="rounded-l-md">id</th>
                    <th>Name</th>
                    <th>Value</th>
                    <th className="rounded-r-md w-ma">Action</th>
                  </tr>
                </thead>
                <tbody className="[&>*]:border-b-[1px] border-gray-100 text-xs">
                  {assignedFeatures?.map((row, index) => (
                    <tr
                      key={index}
                      className="[&>*]:py-4 text-center [&>*]:px-3"
                    >
                      <td>{index + 1}</td>
                      <td>{row?.feature?.name}</td>
                      <td>
                        <TextField
                          id={"value" + index}
                          placeholder={
                            row?.feature?.type === "limit"
                              ? "Value"
                              : "Yes or No Type Feature"
                          }
                          value={row?.feature?.type === "limit" && row?.value}
                          onChange={(e) => {
                            const data = [...assignedFeatures];
                            data[index] = {
                              ...data[index],
                              value: e.target.value,
                            };
                            setAssignedFeatures(data);
                          }}
                          disabled={row?.feature?.type === "yesOrNo"}
                          name="value"
                        />
                      </td>
                      <td className="flex justify-center items-center">
                        <RiDeleteBinLine
                          size={21}
                          className="text-red-500 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteFeature(row?.feature?.featureId);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>

          <div className="grid md:grid-cols-12 items-end">
            <div className="md:col-start-10 col-span-3">
              <Button
                type="primary"
                className="w-full"
                onClick={handleAssignFeatures}
              >
                Assign Features
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        visibility={isDeleteModalOpen?.status}
        onChange={() => handleDeleteFeature(null)}
      >
        <div className="grid gap-2">
          <div className="grid gap-2">
            <div className="bg-secondary-500/10 dark:bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none rounded-md rounded-b-none">
              <div className="font-bold dark:text-darkModeText">
                Delete Category
              </div>
            </div>
            <div className="text-sm text-gray-500  p-4 space-y-3">
              <div>Are You Sure to Delete This?</div>
              <div>NB:Please Click Assign Features Button to Complete</div>
            </div>
          </div>
          <div className="flex w-full justify-end gap-3  p-2 py-2">
            <div className="w-1/6">
              <Button
                className="w-full"
                onClick={() => handleDeleteFeature(null)}
              >
                No
              </Button>
            </div>
            <div className="w-1/6">
              <Button
                className="w-full"
                type="primary"
                onClick={handleSubmitDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddPlan;
