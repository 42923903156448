import axios from "axios";
import { baseUrl } from "../config";
import jsCookie from "js-cookie";

const Remote = axios.create({
  baseURL: baseUrl,
});

const requestHandler = (request) => {
  request.headers.Authorization =
    jsCookie.get("token") && `Bearer ${JSON.parse(jsCookie.get("token"))}`;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    window.location.href = "/auth/login";
  }
  return Promise.reject(error);
};

Remote.interceptors.request.use((request) => requestHandler(request));

Remote.interceptors.response.use(responseHandler, responseErrorHandler);

export default Remote;
