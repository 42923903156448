import { useState, useEffect } from "react";

import { RiAddCircleFill, RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import {
  addFeature,
  deleteFeature,
  getAllFeatures,
  updateFeature,
} from "../../api/plans";
import Button from "../../features/ui/elements/Button";
import ModalComponent from "../../features/ui/elements/ModalComponent";
import TextField from "../../features/ui/elements/TextField";
import TabView from "../../features/ui/elements/TabView";

const Features = () => {
  const [features, setFeatures] = useState();
  const [feature, setFeature] = useState({
    id: null,
    name: "",
    price: "",
    slug: "",
    type: "limit",
  });
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    type: "add",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
    status: false,
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  //   const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getAllFeatures();
    setFeatures(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModalChange = (type) => {
    setIsModalOpen({
      status: !isModalOpen?.status,
      type,
    });
  };

  const handleButtonChange = (type) =>
    setFeature({
      ...feature,
      type,
    });

  const handleEditFeature = async (feature) => {
    if (feature) {
      setFeature(feature);
      handleModalChange("edit");
    }
  };

  const handleSubmit = async () => {
    const data =
      isModalOpen?.type === "add"
        ? await addFeature({
            name: feature?.name,
            slug: feature?.slug,
            price: feature?.price,
            type: feature?.type,
          })
        : await updateFeature(feature?.id, {
            name: feature?.name,
            slug: feature?.slug,
            price: feature?.price,
            type: feature?.type,
          });
    if (data) {
      fetchData();
      handleModalChange(null);
    }
  };

  const handleDeleteFeature = (id) =>
    setIsDeleteModalOpen({ status: !isDeleteModalOpen?.status, id });

  const handleSubmitDelete = async () => {
    const data = await deleteFeature(isDeleteModalOpen?.id);
    if (data) {
      fetchData();
      handleDeleteFeature(null);
    }
  };

  return (
    <div className="grid gap-4 p-4">
      <div className="flex gap-2 items-center">
        <h2 className="text-2xl font-extrabold dark:text-darkModeText">
          Features
        </h2>
        {/* <Link href="requests/new"> */}
        <RiAddCircleFill
          size={35}
          className="text-secondary-500 cursor-pointer"
          onClick={() => handleModalChange("add")}
        />
        {/* </Link> */}
      </div>
      <div className="overflow-auto space-y-2">
        <table className="table-auto w-full whitespace-nowrap">
          <thead className=" p-3 rounded-full">
            <tr className="[&>*]:p-3 text-xs [&>*]:font-medium tracking-wide text-[#033155] dark:text-darkModeText">
              <th className="rounded-l-md">id</th>
              <th>Name</th>
              <th>Price</th>
              <th>Type</th>
              <th className="rounded-r-md w-ma">Action</th>
            </tr>
          </thead>
          <tbody className="[&>*]:border-b-[1px] border-gray-100 text-xs">
            {!isLoading ? (
              features?.map((row, index) => (
                <tr
                  key={index}
                  className="[&>*]:py-4 text-center [&>*]:px-3"
                  // onClick={() =>
                  //   navigate("sub-categories", {
                  //     state: { id: row?.id },
                  //   })
                  // }
                >
                  <td>{index + 1}</td>
                  <td>{row?.name}</td>
                  <td>{row?.price}</td>
                  <td>{row?.type === "limit" ? "Limit" : "Yes or No"}</td>
                  <td className="flex justify-center items-center gap-1">
                    <RiPencilLine
                      onClick={() => handleEditFeature(row)}
                      className="cursor-pointer"
                      size={15}
                    />
                    <RiDeleteBinLine
                      size={16}
                      className="text-red-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteFeature(row?.id);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="grid w-full justify-center text-primary-500 p-2">
                    <div className="loading loading-sm" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!isLoading && !features?.length && (
          <div className="grid w-full text-gray-400 italic justify-center text-xs">
            No Data Found
          </div>
        )}
      </div>
      <ModalComponent
        visibility={isModalOpen?.status}
        onChange={() => handleModalChange(null)}
      >
        <div className="flex justify-between items-center bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none">
          <div className="text-lg dark:text-darkModeText font-semibold font-sans">
            {`${isModalOpen?.type === "add" ? "Add" : "Edit"} Feature`}
          </div>
          <div
            className="dark:text-darkModeText font-semibold font-sans cursor-pointer"
            onClick={() => handleModalChange(null)}
          >
            X
          </div>
        </div>
        <div className="p-1 py-4">
          <div className="grid gap-3 p-2 px-2 text-[0.7rem]">
            <div className="space-y-2">
              <TextField
                id="feature"
                placeholder="Feature Name"
                value={feature?.name}
                onChange={(e) =>
                  setFeature({
                    ...feature,
                    name: e.target.value,
                  })
                }
                name="feature"
                // register={register}
                // validationRules={{
                //   required: "Name is empty",
                // }}
                // error={errors?.name}
              />
              <TextField
                id="price"
                placeholder="Feature Price"
                value={feature?.price}
                onChange={(e) =>
                  setFeature({
                    ...feature,
                    price: e.target.value,
                  })
                }
                name="price"
                // register={register}
                // validationRules={{
                //   required: "Name is empty",
                // }}
                // error={errors?.name}
              />

              <TextField
                id="slug"
                placeholder="Feature Slug"
                value={feature?.slug}
                onChange={(e) =>
                  setFeature({
                    ...feature,
                    slug: e.target.value,
                  })
                }
                name="slug"
                // register={register}
                // validationRules={{
                //   required: "Name is empty",
                // }}
                // error={errors?.name}
              />
              <TabView
                onChange={handleButtonChange}
                options={[
                  { label: "Limit", value: "limit" },
                  { label: "Yes or No", value: "yesOrNo" },
                ]}
                defaultChecked={feature?.type}
                type="colorPrimary"
                name="type"
              />
            </div>
            <div className="text-end">
              <Button type="primary" className="px-12" onClick={handleSubmit}>
                {`${isModalOpen?.type === "add" ? "Add" : "Update"}`}
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        visibility={isDeleteModalOpen?.status}
        onChange={() => handleDeleteFeature(null)}
      >
        <div className="grid gap-2">
          <div className="grid gap-2">
            <div className="bg-secondary-500/10 dark:bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none rounded-md rounded-b-none">
              <div className="font-bold dark:text-darkModeText">
                Delete Feature
              </div>
            </div>
            <div className="text-sm text-gray-500  p-4 ">
              Are You Sure to Delete This?
            </div>
          </div>
          <div className="flex w-full justify-end gap-3  p-2 py-2">
            <div className="w-1/6">
              <Button
                className="w-full"
                onClick={() => handleDeleteFeature(null)}
              >
                No
              </Button>
            </div>
            <div className="w-1/6">
              <Button
                className="w-full"
                type="primary"
                onClick={handleSubmitDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default Features;
