import { useState, useEffect } from "react";

const ModalComponent = ({
  visibility = undefined,
  children = undefined,
  onChange = undefined,
  className = undefined,
}) => {
  const [model, setModel] = useState(visibility);

  useEffect(() => {
    setModel(visibility);
  }, [visibility]);

  return (
    model && (
      <div className="fixed overflow-hidden text-black inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[999]">
        {model && (
          <div
            className="absolute bg-transparent w-full h-full inset-0"
            onClick={() => (onChange ? onChange(false) : setModel(false))}
          ></div>
        )}
        <div
          className={`relative bg-white dark:bg-darkModeBg-primary dark:shadow-primary-500 dark:shadow-1x rounded-md my-6 z-[999] mx-4 w-3/4 sm:w-1/2 ${className}`}
        >
          <div className="overflow-scroll">{children}</div>
        </div>
      </div>
    )
  );
};

export default ModalComponent;
