import toast from "react-hot-toast";
import Remote from "../services/network";

export const getCategories = async () => {
  try {
    const data = await Remote.get("/categories/");
    return data?.data?.data;
  } catch (e) {}
};

export const getSingleCategory = async (id, reqBody) => {
  try {
    const data = await Remote.get("/categories/" + id, reqBody);
    return data?.data?.data;
  } catch (e) {}
};

export const addCategory = async (reqBody) => {
  try {
    const data = await Remote.post("/categories", reqBody);
    toast.success("Category Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateCategory = async (id, reqBody) => {
  try {
    const data = await Remote.post("/categories/" + id + "/update", reqBody);
    toast.success("Category Updated Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteCategory = async (id) => {
  try {
    const data = await Remote.post("/categories/" + id + "/delete");
    toast.success("Category Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getSubCategories = async (id) => {
  try {
    const data = await Remote.get("/categories/" + id + "/children");
    return data?.data?.data;
  } catch (e) {}
};
