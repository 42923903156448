import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex gap-8 flex-col p-4 items-center justify-center h-screen bg-secondary-500">
      <img
        className="block md:hidden cursor-pointer"
        src={"/images/logo_white.svg"}
        alt="logo"
        width={200}
        onClick={() => navigate("/")}
      />
      <div className="grid gap-8 text-secondary-500 bg-white rounded-md shadow-2xl justify-center p-10 items-center">
        <div>
          <div className="text-8xl text-center">404</div>
          <div className="text-center">
            The Page You are looking for, Couldn't be Found.
          </div>
        </div>
        <div className="grid justify-center">
          <img
            className="hidden md:block cursor-pointer"
            src={"/images/logo.svg"}
            alt="logo"
            width={150}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
