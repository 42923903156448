import toast from "react-hot-toast";
import Remote from "../services/network";

export const getAllFeatures = async () => {
  try {
    const data = await Remote.get("/plans/features");
    return data?.data?.data;
  } catch (e) {}
};

export const addFeature = async (reqBody) => {
  try {
    const data = await Remote.post("/plans/features", reqBody);
    toast.success("Feature Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateFeature = async (id, reqBody) => {
  try {
    const data = await Remote.post(
      "/plans/features/" + id + "/update",
      reqBody
    );
    toast.success("Feature Updated Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteFeature = async (id) => {
  try {
    const data = await Remote.post("/plans/features/" + id + "/delete");
    toast.success("Feature Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const getAllPlans = async () => {
  try {
    const data = await Remote.get("/plans");
    return data?.data?.data;
  } catch (e) {}
};

export const getSinglePlan = async (id) => {
  try {
    const data = await Remote.get("/plans/" + id);
    return data?.data?.data;
  } catch (e) {}
};

export const addPlan = async (reqBody) => {
  try {
    const data = await Remote.post("/plans", reqBody);
    toast.success("Plan Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updatePlan = async (id, reqBody) => {
  try {
    const data = await Remote.post("/plans/" + id + "/update", reqBody);
    toast.success("Plan Updated Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const assignFeatures = async (id, reqBody) => {
  try {
    const data = await Remote.post(
      "/plans/" + id + "/assign-features",
      reqBody
    );
    toast.success("Features Assigned Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deletePlan = async (id) => {
  try {
    const data = await Remote.post("/plans/" + id + "/delete");
    toast.success("Plan Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
