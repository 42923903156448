import { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

const SelectField = ({
  options = [],
  value = undefined,
  onChange = undefined,
  placeholder = undefined,
  className = "",
  name = undefined,
  disabled = undefined,
}) => {
  const [open, changeStatus] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  const handleChange = () => changeStatus(!open);

  useEffect(() => {
    if (typeof value !== undefined) {
      setActiveItem(options?.find((option) => option?.value == value));
    }
  }, [value, options]);

  return (
    <div className="w-full">
      {open && (
        <div
          className="absolute bg-transparent w-screen h-screen inset-0"
          onClick={handleChange}
        ></div>
      )}
      <div
        className={"relative text-xs dark:bg-darkModeBg-primary"}
        onClick={handleChange}
      >
        <div
          className={`relative rounded-md bg-white border p-3 flex justify-between ${
            !disabled && "cursor-pointer"
          } 
            ${open && !disabled && "border-primary-500"} ${
            disabled && "dark:border-gray-700"
          } ${className}`}
        >
          <input
            readOnly
            // placeholder={placeholder}
            type="text"
            className="dark:bg-darkModeBg-primary  dark:text-darkModeText w-full  focus:outline-none pointer-events-none disabled:bg-white dark:disabled:bg-darkModeBg-primary"
            value={activeItem?.label || ""}
            disabled={disabled}
          />
          <label
            className={
              "absolute transform transition-all px-0.5  " +
              (open && !disabled
                ? "-translate-y-full bg-transparent dark:bg-darkModeBg-primary h-1/2 text-primary-500 "
                : activeItem
                ? "-translate-y-full bg-transparent h-1/2 text-gray-400 dark:bg-darkModeBg-primary "
                : "text-gray-400 dark:bg-darkModeBg-primary")
            }
          >
            {placeholder}
          </label>
          <div className="inline-flex items-center dark:bg-darkModeBg-primary dark:text-darkModeText">
            <RiArrowDownSLine size={16} />
          </div>
        </div>
        {open && !disabled && (
          <div
            className="grid max-h-48 overflow-y-auto absolute w-full [&>*]:p-2 drop-shadow-sm rounded-md border border-gray-200 bg-gray-100 text-xs z-10 overflow-hidden"
            onClick={() => changeStatus(!open)}
          >
            {/* / id: individual item id, name: selectfield name */}
            {options?.map((item) => (
              <button
                key={item.value}
                value={item.value}
                name={name}
                id={item?.label}
                className="hover:bg-gray-200 text-left h-10"
                onClick={(e) => {
                  setActiveItem(item);
                  onChange(e);
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectField;
