import { toast } from "react-hot-toast";
import Remote from "../services/network";

export const register = async (reqBody) => {
  try {
    const data = await Remote.post("/auth/register", reqBody);
    toast.success("Registration Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const login = async (reqBody) => {
  try {
    const data = await Remote.post("/auth/login", reqBody);
    toast.success("Login Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
  