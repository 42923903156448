import { toast } from "react-hot-toast";
import Remote from "../services/network";

export const getUsers = async (page = 1, limit = 10) => {
  const queryParams = {
    page,
    limit,
    // type: "activeAndInactive",
  };

  const data = await Remote.get("/customers", {
    params: queryParams,
  });
  return data?.data?.data;
};

export const getDeletedUsers = async (page = 1, limit = 10) => {
  const queryParams = {
    page,
    limit,
    type: "deleted",
  };

  const data = await Remote.get("/customers", {
    params: queryParams,
  });
  return data?.data?.data;
};

export const getSingleUser = async (id) => {
  try {
    const data = await Remote.get("/customers/" + id);
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteUser = async (id) => {
  try {
    const data = await Remote.post("/customers/" + id + "/delete/");
    toast.success("Customer Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const activateUser = async (id) => {
  try {
    const data = await Remote.post("/customers/" + id + "/activate");
    toast.success("User Activated Successfully");
    return data?.data?.data;
  } catch (error) {}
};

export const deactivateUser = async (id) => {
  try {
    const data = await Remote.post("/customers/" + id + "/deactivate");
    toast.success("User Deactivated Successfully");
    return data?.data?.data;
  } catch (error) {}
};

export const approveUser = async (id) => {
  try {
    const data = await Remote.post("/customers/" + id + "/approve");
    toast.success("User Approved Successfully");
    return data?.data?.data;
  } catch (error) {}
};
