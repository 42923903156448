import { useState } from "react";
import { useNavigate } from "react-router-dom";
import jsCookie from "js-cookie";
import { RiArrowDownSLine } from "react-icons/ri";

const options = [{ label: "Logout", value: "logout" }];

export const Header = () => {
  const [open, changeOpen] = useState();

  const navigate = useNavigate();

  const handleChange = () => changeOpen(!open);

  return (
    <div className="flex p-3 px-4 justify-end items-center w-full border-b border-gray-300 h-16 top-0 bg-white z-[666]">
      {/* <div className="font-extrabold text-3xl cursor-pointer">
        <img src={"/images/logo.svg"} alt="logo" height={60} width={140} />
      </div> */}

      {open && (
        <div
          className="absolute bg-transparent w-screen h-screen inset-0"
          onClick={handleChange}
        ></div>
      )}
      <div className={"relative "}>
        <div
          className="flex items-center ml-10 md:ml-none text-sm cursor-pointer"
          onClick={handleChange}
        >
          Admin
          <RiArrowDownSLine size={20} className="text-gray-500" />
        </div>

        {open && (
          <div className="grid absolute top-6 right-0 min-w-full [&>*]:p-2 drop-shadow-sm rounded-md border border-gray-200 bg-gray-100 text-xs z-10 overflow-hidden">
            <div className="text-primary-500 whitespace-nowrap border-b-[1px] border-gray-200">
              Admin
            </div>
            {options?.map((item) => (
              <button
                key={item.value}
                // value={item.value}
                className={`${
                  item?.value === "upgradePlan" && "lg:hidden"
                } hover:bg-gray-200 text-left h-10`}
                onClick={() => {
                  changeOpen(!open);
                  if (item.value === "logout") {
                    jsCookie.remove("token");
                    jsCookie.remove("user");
                    navigate("/auth/login");
                  }
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
