const { Outlet } = require("react-router-dom");

const AuthLayout = () => {
  return (
    <div className="flex flex-col md:grid h-screen md:grid-cols-2">
      <div className="relative grid px-4 py-5 h-20 md:h-full shadow-lg items-center md:justify-center bg-secondary-900">
        <div className="relative">
          <img
            className="hidden md:block "
            src={"/images/logo_white.svg"}
            alt="logo"
            width={250}
          />
          <img
            className="md:hidden"
            src={"/images/logo_white.svg"}
            alt="logo"
            width={140}
          />
          {/* <div className="absolute text-xs md:text-base left-[6.5rem] md:left-auto md:right-0 -bottom-2 text-white">
            Admin
          </div> */}
        </div>
      </div>

      <div className="h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
