const styles = {
  secondary: `bg-white peer-checked:bg-gray-200 peer-checked:text-black rounded-t-md`,
  medium: `bg-white peer-checked:bg-gray-200 peer-checked:border-b peer-checked:text-black rounded-t-md`,
  tertiary: `bg-white peer-checked:bg-gray-200 peer-checked:text-black rounded-md py-2`,
  colorPrimary: `peer-checked:border-b-2 peer-checked:border-b-secondary-500 peer-checked:text-black dark:peer-checked:text-darkModeText py-2 bg-primary-100`,
  default: `bg-white rounded-md peer-checked:bg-white peer-checked:border-primary-500 peer-checked:text-primary-500 peer-checked:text-black border`,
};

export default function TabView({
  name = undefined || "field",
  onChange = undefined,
  options = undefined,
  defaultChecked = undefined,
  type = "default",
  value,
}) {
  return (
    <div
      className={`overflow-scroll ${type === "secondary" && " border-b "} ${
        type === "medium" && " border-b "
      } ${
        type === "colorPrimary" && " dark:border-[1px] dark:border-primary-500 "
      }`}
    >
      <div
        className={
          "flex items-start w-full whitespace-nowrap " +
          (type === "secondary" && " space-x-3 ") +
          (type === "medium" && " w-3/5 ")
        }
      >
        {options &&
          options?.map((option, index) => {
            return (
              <label className={"block w-full "} key={index}>
                <input
                  type="radio"
                  hidden
                  name={name}
                  value={option.value}
                  className="appearance-none peer"
                  onChange={() => {
                    onChange(option.value);
                  }}
                  checked={option.value === defaultChecked}
                />
                <span
                  className={`text-black text-center cursor-pointer p-4 py-2.5 block w-full text-xs dark:bg-darkModeBg-primary dark:text-darkModeText ${styles[type]}`}
                >
                  {option.label}
                  {type === "medium" && option?.count && (
                    <span className="ml-1 rtl:mr-1 border px-1 py-[2px] font-normal text-[0.6rem] border-primary-500 rounded-full">
                      {option?.count}
                    </span>
                  )}
                </span>
              </label>
            );
          })}
      </div>
    </div>
  );
}
