import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import { Header, Sidebar } from "./features/ui";
import { Dashboard, Users } from "./pages";
import { Categories } from "./pages/categories";
import Countries from "./pages/countries";
import toast, { Toaster, useToasterStore } from "react-hot-toast";
import SubCategories from "./pages/categories/sub-categories";
import Features from "./pages/features";
import Plans from "./pages/plans";
import AddPlan from "./pages/plans/add";
import DeletedUsers from "./pages/users/deleted";
import AddCoupon from "./pages/coupons/add";
import Coupons from "./pages/coupons";
import DefaultLayout from "./features/ui/Layouts/Default";
import Register from "./pages/register";
import AuthLayout from "./features/ui/Layouts/Auth";
import Login from "./pages/login";
import ErrorPage from "./pages/error";
import DocumentTypes from "./pages/document-types";
// import {Header} from '@features/ui'

function App() {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= 1) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <Router>
      <Toaster
        toastOptions={{
          style: {
            color: "white",
          },
          success: {
            style: {
              background: "green",
            },
          },
          error: {
            style: {
              background: "#ef4444",
            },
          },
          loading: {
            iconTheme: {
              primary: "#fb923c",
            },
            style: {
              border: "2px solid orange",
              color: "#fb923c",
            },
          },
        }}
      />
      <Routes>
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
        <Route path="" element={<AuthLayout />}>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
        </Route>
        <Route path="" element={<DefaultLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="/users/deleted" element={<DeletedUsers />} />
          <Route path="/categories" element={<Categories />} />
          <Route
            path="/categories/sub-categories"
            element={<SubCategories />}
          />
          <Route path="/document-types" element={<DocumentTypes />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/features" element={<Features />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/plans/add" element={<AddPlan />} />
          <Route path="/plans/edit" element={<AddPlan />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/coupons/add" element={<AddCoupon />} />
          <Route path="/coupons/edit" element={<AddCoupon />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>

      {/* <div className="flex h-screen w-full">
        <Toaster
          toastOptions={{
            style: {
              color: "white",
            },
            success: {
              style: {
                background: "green",
              },
            },
            error: {
              style: {
                background: "#ef4444",
              },
            },
            loading: {
              iconTheme: {
                primary: "#fb923c",
              },
              style: {
                border: "2px solid orange",
                color: "#fb923c",
              },
            },
          }}
        />
        <Sidebar />

        <div className="grid md:ml-56 h-full w-full">
          <Header />
          <div className="w-full mt-16">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/deleted" element={<DeletedUsers />} />
              <Route path="/categories" element={<Categories />} />
              <Route
                path="/categories/sub-categories"
                element={<SubCategories />}
              />
              <Route path="/countries" element={<Countries />} />
              <Route path="/features" element={<Features />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/plans/add" element={<AddPlan />} />
              <Route path="/plans/edit" element={<AddPlan />} />
              <Route path="/coupons" element={<Coupons />} />
              <Route path="/coupons/add" element={<AddCoupon />} />
              <Route path="/coupons/edit" element={<AddCoupon />} />
            </Routes>
          </div>
        </div>
      </div> */}
    </Router>
  );
}

export default App;
