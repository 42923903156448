import { useEffect, useState } from "react";
import {
  RiDeleteBinLine,
  RiEyeLine,
  RiFolderReceivedLine,
} from "react-icons/ri";
import {
  activateUser,
  deactivateUser,
  deleteUser,
  getDeletedUsers,
  getSingleUser,
  getUsers,
} from "../../../api/users";
import Button from "../../../features/ui/elements/Button";
import ModalComponent from "../../../features/ui/elements/ModalComponent";
import { RadioButton } from "../../../features/ui/elements/RadioButton";
import Pagination from "../../../features/ui/elements/Pagination";
import SelectField from "../../../features/ui/elements/SelectField";

const viewCountList = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const DeletedUsers = () => {
  const [users, setUsers] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
    status: false,
    id: null,
  });
  const [pageSize, setPageSize] = useState(viewCountList[0]?.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState({ id: null });
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    const users = await getDeletedUsers(currentPage, pageSize);

    if (users) {
      setTotalItems(users?.totalItems);
      setUsers(users?.customers);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handleDeleteUser = (id) =>
    setIsDeleteModalOpen({ status: !isDeleteModalOpen?.status, id });

  const handleSubmitDelete = async () => {
    const data = await deleteUser(isDeleteModalOpen?.id);
    if (data) {
      fetchData();
      handleDeleteUser(null);
    }
  };

  const handleChangeStatus = async (id) => {
    const data = await activateUser(id);
    if (data) {
      fetchData();
    }
  };

  const handleViewCount = (e) => {
    setCurrentPage(1);
    setPageSize(e.target.value);
  };

  const handleModalChange = () => setIsModalOpen(!isModalOpen);

  const handleViewUser = async (id) => {
    const data = await getSingleUser(id);
    if (data) {
      setUser(data);
      handleModalChange();
    }
  };

  return (
    <div className="grid gap-4 p-4">
      {console.log("users ", users)}
      <div className="flex justify-between">
        <div className="text-2xl font-extrabold">Users</div>
        {users?.length ? (
          <div>
            <SelectField
              options={viewCountList}
              value={pageSize}
              onChange={handleViewCount}
              placeholder={"View Count"}
              className="p-[0.5rem]"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div>
        <table className="table-auto w-full">
          <thead className="bg-gray-200 p-3 rounded-full">
            <tr className="[&>*]:p-3 text-sm [&>*]:font-normal text-left">
              <th className="rounded-l-md">ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Type</th>
              <th className="rounded-r-md w-ma">Action</th>
            </tr>
          </thead>
          <tbody className="[&>*]:border-b-[1px] border-gray-100 text-xs">
            {!isLoading ? (
              users?.map((row, index) => (
                <tr key={index} className="[&>*]:py-4 [&>*]:px-3">
                  <td>{index + 1}</td>
                  <td>{row.name ?? "-"}</td>
                  <td>{row.email ?? "-"}</td>
                  <td>{row.phone ?? "-"}</td>
                  <td>{row?.type ?? "-"}</td>
                  <td className="flex items-center gap-1">
                    <RiFolderReceivedLine
                      size={19}
                      className="cursor-pointer"
                      onClick={() => handleChangeStatus(row?.id)}
                    />
                    <RiEyeLine
                      onClick={() => handleViewUser(row?.id)}
                      className="cursor-pointer text-gray-500"
                      size={19}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="grid w-full justify-center text-primary-500 p-2">
                    <div className="loading loading-sm" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isLoading && !users?.length && (
        <div className="grid w-full text-gray-400 italic justify-center text-xs">
          No Data Found
        </div>
      )}
      {users?.length ? (
        <div className="grid justify-end items-center text-xs">
          <Pagination
            totalItems={totalItems}
            itemPerPage={pageSize}
            currentItem={currentPage}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            }}
          />
        </div>
      ) : (
        ""
      )}
      <ModalComponent
        visibility={isDeleteModalOpen?.status}
        onChange={() => handleDeleteUser(null)}
      >
        <div className="grid gap-2">
          <div className="grid gap-2">
            <div className="bg-secondary-500/10 dark:bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none rounded-md rounded-b-none">
              <div className="font-bold dark:text-darkModeText">
                Delete Category
              </div>
            </div>
            <div className="text-sm text-gray-500  p-4 space-y-3">
              <div>Are You Sure to Delete This?</div>
            </div>
          </div>
          <div className="flex w-full justify-end gap-3  p-2 py-2">
            <div className="w-1/6">
              <Button className="w-full" onClick={() => handleDeleteUser(null)}>
                No
              </Button>
            </div>
            <div className="w-1/6">
              <Button
                className="w-full"
                type="primary"
                onClick={handleSubmitDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent visibility={isModalOpen} onChange={handleModalChange}>
        <div className="flex justify-between items-center bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none">
          <div className="text-lg dark:text-darkModeText font-semibold font-sans">
            Profile
          </div>
          <div
            className="dark:text-darkModeText font-semibold font-sans cursor-pointer"
            onClick={handleModalChange}
          >
            X
          </div>
        </div>
        <div className="p-1">
          <div className="grid gap-3 p-4 text-[0.7rem]">
            <div className="grid text-[0.8rem] gap-8 grid-cols-12 bg-secondary-100/10 items-center p-4 border-[1px] rounded-md">
              <div className="grid col-span-4 justify-center">
                <div className="grid items-center justify-center h-32 w-32 rounded-full border-2 italic">
                  Profile Image
                </div>
              </div>
              <div className="col-span-8 space-y-2">
                <div className="space-x-2">
                  <span className="font-semibold">Name:</span>
                  <span>{user?.name ?? "-"}</span>
                </div>
                <div className="space-x-2">
                  <span className="font-semibold">Email:</span>
                  <span>{user?.email ?? "-"}</span>
                </div>
                <div className="space-x-2">
                  <span className="font-semibold">Phone:</span>
                  <span>{user?.phone ?? "-"}</span>
                </div>
                <div className="space-x-2">
                  <span className="font-semibold">Landline:</span>
                  <span>{user?.landline ?? "-"}</span>
                </div>
                <div className="space-x-2">
                  <span className="font-semibold">Type:</span>
                  <span>{user?.type ?? "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default DeletedUsers;
