import { useEffect } from "react";

const TextField = ({
  id = undefined,
  name = undefined,
  value = undefined,
  placeholder = undefined,
  onChange = undefined,
  iconButton = undefined,
  iconButtonAction = undefined,
  multiline = false,
  multilineRows = undefined,
  multilineColumns = undefined,
  type = undefined,
  validationRules = undefined,
  error = undefined,
  register,
  ...props
}) => {
  useEffect(() => {}, [value]);

  return (
    <div className="grid gap-1">
      <div
        className={`flex justify-between group border focus-within:border-secondary-500 rounded-md ${
          props?.disabled && "dark:border-gray-200"
        }`}
      >
        <label className="relative w-full min-w-[8rem]">
          {multiline === true ? (
            <textarea
              id={id}
              {...(register && register(name, validationRules))}
              required
              rows={multilineRows}
              cols={multilineColumns}
              value={value || ""}
              name={name}
              onChange={onChange}
              className="disabled:bg-white dark:disabled:bg-darkModeBg-primary placeholder:text-white rounded-md dark:bg-darkModeBg-primary w-full h-full px-4 py-3 text-xs focus:outline-none peer resize-none dark:text-darkModeText"
              {...props}
            />
          ) : (
            <input
              id={id}
              {...(register && register(name, validationRules))}
              required
              value={value || ""}
              name={name}
              onChange={onChange}
              type={type}
              className="rounded-md disabled:bg-white dark:disabled:bg-darkModeBg-primary dark:bg-darkModeBg-primary placeholder:text-white w-full px-4 py-3 text-xs focus:outline-none peer dark:text-darkModeText"
              {...props}
            />
          )}
          <span
            className={
              multiline === true
                ? `h-auto group-focus-within:h-auto input-floating-label-primary peer-valid:h-1/5 text-gray-500 cursor-text ${
                    props?.disabled &&
                    `-translate-y-full h-1/5 bg-white dark:bg-darkModeBg-primary`
                  }`
                : `input-floating-label-primary text-gray-500 cursor-text ${
                    props?.disabled &&
                    `text-gray-300 bg-white dark:bg-darkModeBg-primary`
                  }`
            }
          >
            {placeholder}
          </span>
        </label>
        <div
          className="text-xs flex items-center gap-1 px-2 cursor-pointer"
          onClick={iconButtonAction}
        >
          {iconButton}
        </div>
      </div>
      <div className="text-xs italic text-red-500 grid justify-end px-1">
        {error?.message}
      </div>
    </div>
  );
};

export default TextField;
