import React from "react";
import RCPagination from "rc-pagination";
import { RiArrowLeftLine, RiArrowRightLine, RiMoreLine } from "react-icons/ri";

export default function Pagination({
  totalPages = 0,
  itemPerPage = 10,
  onChange = undefined,
  currentItem = undefined,
  onShowSizeChange = undefined,
  totalItems = undefined,
}) {
  return (
    <RCPagination
      total={totalItems}
      pageSize={itemPerPage}
      className="flex space-x-2 items-center "
      itemRender={(current, type, element) => (
        <button
          className={` w-7 h-7 text-xs rounded-full bg-secondary-500/20text-black dark:text-darkModeText flex items-center justify-center hover:bg-secondary-500/30 ${
            current == currentItem
              ? "bg-secondary-900 text-white "
              : " text-black bg-secondary-900/10 dark:bg-secondary-900/25 "
          }`}
        >
          {element}
        </button>
      )}
      showLessItems
      showTitle={false}
      showTotal={(total, range) =>
        `Showing ${range[0]}-${range[1]} of ${total}`
      }
      prevIcon={<RiArrowLeftLine />}
      nextIcon={<RiArrowRightLine />}
      jumpNextIcon={<RiMoreLine />}
      jumpPrevIcon={<RiMoreLine />}
      current={currentItem}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
    />
  );
}
