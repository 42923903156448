import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { RiCalendar2Fill } from "react-icons/ri";

{
  /**
   * Note: some styles of DatePickerField component are overriden in globalThis.css
   */
}

const DatePickerField = ({
  value = undefined,
  name = undefined,
  placeholder = undefined,
  onChange = undefined,
  disabled = undefined,
}) => {
  const [dateValue, setValueDate] = useState(value);
  const [onChangeDate, setOnChangeDate] = useState(onChange);

  useEffect(() => {
    setValueDate(value);
  }, [value]);

  // useEffect(() => {
  //   if (onChange) {
  //     if (value) {
  //       setOnChangeDate(onChange);
  //     } else {
  //       setOnChangeDate(handleChange);
  //     }
  //   } else {
  //     setOnChangeDate(handleChange);
  //   }
  // }, [onChange, value]);

  const handleChange = (date) => setValueDate(date);

  return (
    <div
      className={`flex justify-between group border focus-within:border-secondary-500 rounded-md `}
    >
      <div className="relative w-full p-1">
        <div className="flex items-center gap-4">
          <RiCalendar2Fill size={28} className="text-gray-500 p-1 pt-2" />
          <div className="relative -left-4 w-full">
            <ReactDatePicker
              selected={dateValue}
              onChange={onChange}
              name={name}
              disabled={disabled}
              className=" disabled:bg-white text-xs w-full focus:outline-none"
            />
          </div>
        </div>
        {placeholder && (
          <span
            className={
              "ml-5 transform transition-all absolute top-2 h-3/5 flex items-center text-center px-2 text-xs " +
              (dateValue
                ? " bg-white h-2/5 -translate-y-full px-1 text-gray-500  "
                : " text-gray-500 pointer-events-none")
            }
          >
            {placeholder}
          </span>
        )}
      </div>
    </div>
  );
};

export default DatePickerField;
