export const RadioButton = ({
  onChange = undefined,
  boolValue = undefined,
  id = undefined,
}) => (
  <div
    className={`flex p-[2px] border-[1px] border-slate-200 ${
      boolValue ? "pl-6 flex-row-reverse bg-secondary-500" : "pr-6"
    } rounded-full`}
    onClick={onChange}
    id={id}
  >
    <div className="rounded-full h-5 w-5 bg-white border-2"></div>
  </div>
);
