import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { register } from "../../api/auth";

import Button from "../../features/ui/elements/Button";
import TextField from "../../features/ui/elements/TextField";

const Register = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [cookie, setCookie] = useCookies(["user"]);

  const navigate = useNavigate();

  const onChange = (e) => {
    const id = e.target.name;
    const value = e.target.value;
    const userData = { ...form, [id]: value };
    setForm(userData);
  };

  const handleSubmit = async () => {
    const data = await register(form);
    if (data) {
      await setCookie("user", JSON.stringify(data?.user), {
        path: "/",
        maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });
      await setCookie("token", JSON.stringify(data?.token), {
        path: "/",
        maxAge: 3600, // Expires after 1hr
        sameSite: true,
      });
      navigate("/dashboard");
    }
  };

  return (
    <div className="flex gap-6 p-8 flex-col h-full justify-center">
      <div className="text-4xl font-extrabold text-secondary-500">Register</div>
      <div className="grid gap-3">
        <div className="w-full">
          <TextField
            id="name"
            placeholder="Name"
            value={form?.name}
            onChange={onChange}
            name="name"
          />
        </div>
        <div className="w-full">
          <TextField
            id="email"
            placeholder="Email"
            value={form?.email}
            onChange={onChange}
            name="email"
          />
        </div>
        <div className="w-full">
          <TextField
            id="password"
            placeholder="Password"
            value={form?.password}
            onChange={onChange}
            name="password"
            type="password"
          />
        </div>
        <div className="w-full">
          <TextField
            id="confirmPassword"
            placeholder="Confirm Password"
            value={form?.confirmPassword}
            onChange={onChange}
            name="confirmPassword"
            type="password"
          />
        </div>
        <div className="w-full">
          <Button
            type="primary"
            className="px-12 w-full"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
        <div className="w-full">
          <Button
            type="secondary"
            className="px-12 w-full"
            onClick={() => navigate("/auth/login")}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Register;
