const styles = {
  default: `bg-gray-200 hover:bg-gray-300 disabled:text-gray-500`,
  primary: `bg-secondary-500 text-white hover:bg-secondary-500/80 disabled:bg-primary-500/50 disabled:text-white/60`,
  secondary: `border-[1px] border-secondary-900 text-secondary-500 hover:border-secondary-900/80 hover:text-secondary-500/80 disabled:border-secondary-900/50 disabled:text-secondary-500/50`,
  danger: `border-[0.1rem] border-primary-500 text-secondary-500 hover:border-red-400 hover:text-red-400 disabled:border-primary-500/50 disabled:text-primary-500/50 text-red-500 border-red-500`,
};

const Button = ({
  children,
  type = "default",
  name = undefined,
  onClick = undefined,
  disabled = undefined,
  className = "",
}) => {
  return (
    <button
      disabled={disabled}
      name={name}
      className={`p-2 text-center rounded-md text-xs cursor-pointer disabled:cursor-not-allowed ${styles[type]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
