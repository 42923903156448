import toast from "react-hot-toast";
import Remote from "../services/network";

export const getCoupons = async () => {
  try {
    const data = await Remote.get("/coupons");
    return data?.data?.data;
  } catch (e) {}
};

export const getSingleCoupon = async (id) => {
  try {
    const data = await Remote.get("/coupons/" + id);
    return data?.data?.data;
  } catch (e) {}
};

export const deleteCoupon = async (id) => {
  try {
    const data = await Remote.post("/coupons/" + id + "/delete");
    toast.success("Coupon Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const addCoupon = async (reqBody) => {
  try {
    const data = await Remote.post("/coupons", reqBody);
    toast.success("Coupon Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateCoupon = async (id, reqBody) => {
  try {
    const data = await Remote.post("/coupons/" + id + "/update", reqBody);
    toast.success("Coupon Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const activateCoupon = async (id) => {
  try {
    const data = await Remote.post("/coupons/" + id + "/activate");
    toast.success("Coupon Activated Successfully");
    return data?.data?.data;
  } catch (error) {}
};

export const deactivateCoupon = async (id) => {
  try {
    const data = await Remote.post("/coupons/" + id + "/deactivate");
    toast.success("Coupon Deactivated Successfully");
    return data?.data?.data;
  } catch (error) {}
};
