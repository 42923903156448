import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiCloseCircleLine,
  RiMenuFill,
} from "react-icons/ri";

const menus = [
  {
    name: "Dashboard",
    slug: "dashboard",
    path: "/",
  },
  {
    name: "Users",
    slug: "users",
    path: "",
    menuList: [
      {
        name: "Users",
        slug: "users",
        path: "/users",
      },

      {
        name: "Deleted Users",
        slug: "deletedUsers",
        path: "/users/deleted",
      },
    ],
  },
  {
    name: "Marketing",
    slug: "marketing",
    path: "",
    menuList: [
      {
        name: "Coupons",
        slug: "coupons",
        path: "/coupons",
      },
    ],
  },
  {
    name: "Master",
    slug: "master",
    path: "",
    menuList: [
      {
        name: "Categories",
        slug: "categories",
        path: "/categories",
      },

      {
        name: "Countries",
        slug: "countries",
        path: "/countries",
      },
      {
        name: "Document Types",
        slug: "documentTypes",
        path: "/document-types",
      },
    ],
  },
  {
    name: "Subscription Plans",
    slug: "subscriptionPlans",
    path: "",
    menuList: [
      {
        name: "Features",
        slug: "features",
        path: "/features",
      },

      {
        name: "Plans",
        slug: "plans",
        path: "/plans",
      },
    ],
  },
];

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeMenu, setActiveMenu] = useState({
    slug: "",
    open: false,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = (menu) => {
    if (menu?.menuList) {
      if (activeMenu?.slug === menu?.slug) {
        setActiveMenu({
          slug: menu?.slug,
          open: !activeMenu?.open,
        });
      } else {
        setActiveMenu({
          slug: menu?.slug,
          open: true,
        });
      }
    } else {
      navigate(menu?.path);
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleMobileSidebar = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="flex fixed flex-col z-[999]  h-screen">
      {isMenuOpen && (
        <div
          className="absolute bg-transparent w-screen h-screen inset-0"
          onClick={handleMobileSidebar}
        ></div>
      )}
      <div
        className={`${
          isMenuOpen ? "hidden" : "block"
        } block md:hidden absolute -right-8 p-2 top-3 bg-secondary-500 rounded-r-2xl text-white`}
        onClick={handleMobileSidebar}
      >
        <RiMenuFill size={20} />
      </div>
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:block bg-secondary-500 z-[999] text-white w-56 h-full`}
      >
        <div className="flex md:hidden justify-end pr-1 pt-1">
          <RiCloseCircleLine
            size={23}
            className="dark:text-white"
            onClick={handleMobileSidebar}
          />
        </div>
        <div className="grid justify-center  pt-6 p-2 font-extrabold text-3xl cursor-pointer">
          <img
            src={"/images/logo_white.svg"}
            alt="logo"
            height={60}
            width={140}
          />
        </div>
        <ul className="py-6">
          {menus?.map((menu, index) => (
            <li
              key={index}
              className={` text-sm border-l-[1px] tracking-wide cursor-pointer 
              ${
                location.pathname === menu.path
                  ? "bg-white/5 border-white"
                  : "border-[#003155]"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleMenuClick(menu);
              }}
            >
              <div className="flex items-center justify-between py-3 px-4 w-full">
                <span>{menu?.name}</span>
                {menu?.menuList && (
                  <span>
                    <RiArrowDownSLine size={17} />
                  </span>
                )}
              </div>
              {activeMenu?.open && activeMenu?.slug === menu?.slug && (
                <div className="bg-primary/5">
                  {menu?.menuList?.map((subMenu, index) => (
                    <li
                      key={index}
                      className={`inline-block text-xs py-3 px-4 w-full border-l-[1px] ${
                        location.pathname === subMenu.path
                          ? "bg-white/5 border-white"
                          : "border-[#003155]"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsMenuOpen(!isMenuOpen);
                        navigate(subMenu?.path);
                      }}
                    >
                      {subMenu?.name}
                    </li>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
