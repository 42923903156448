import toast from "react-hot-toast";
import Remote from "../services/network";

export const getDocumentTypes = async () => {
  try {
    const data = await Remote.get("/utilities/document-types");
    return data?.data?.data;
  } catch (e) {}
};

export const addDocument = async (reqBody) => {
  try {
    const data = await Remote.post("/utilities/document-types", reqBody);
    toast.success("Document Added Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const updateDocument = async (id, reqBody) => {
  try {
    const data = await Remote.post("/utilities/document-types/" + id + "/update", reqBody);
    toast.success("Document Updated Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export const deleteDocument = async (id) => {
  try {
    const data = await Remote.post("/utilities/document-types/" + id + "/delete");
    toast.success("Document Deleted Successfully");
    return data?.data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
