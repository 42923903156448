import { useEffect, useState } from "react";

import { RiAddCircleFill, RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import {
  addCategory,
  deleteCategory,
  getCategories,
  getSingleCategory,
  updateCategory,
} from "../../api/categories";
import ModalComponent from "../../features/ui/elements/ModalComponent";
import Button from "../../features/ui/elements/Button";
import TextField from "../../features/ui/elements/TextField";
import { deletePlan, getAllPlans } from "../../api/plans";
import { RadioButton } from "../../features/ui/elements/RadioButton";

const Plans = () => {
  const [plans, setPlans] = useState();
  const [plan, setPlan] = useState({ id: null, name: "" });
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    type: "add",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState({
    status: false,
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getAllPlans();
    setPlans(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeletePlan = (id) =>
    setIsDeleteModalOpen({ status: !isDeleteModalOpen?.status, id });

  const handleSubmitDelete = async () => {
    const data = await deletePlan(isDeleteModalOpen?.id);
    if (data) {
      fetchData();
      handleDeletePlan(null);
    }
  };

  return (
    <div className="grid gap-4 p-4">
      <div className="flex gap-2 items-center">
        <h2 className="text-2xl font-extrabold dark:text-darkModeText">
          Plans
        </h2>
        {/* <Link href="requests/new"> */}
        <RiAddCircleFill
          size={35}
          className="text-secondary-500 cursor-pointer"
          onClick={() => navigate("/plans/add", { state: { id: null } })}
        />
        {/* </Link> */}
      </div>
      <div className="overflow-auto space-y-2">
        <table className="table-auto w-full whitespace-nowrap">
          <thead className=" p-3 rounded-full">
            <tr className="[&>*]:p-3 text-xs [&>*]:font-medium tracking-wide text-[#033155] dark:text-darkModeText">
              <th className="rounded-l-md">id</th>
              <th>Name</th>
              <th>Is Default</th>
              <th>Is Recommended</th>
              <th className="rounded-r-md w-ma">Action</th>
            </tr>
          </thead>
          <tbody className="[&>*]:border-b-[1px] border-gray-100 text-xs">
            {!isLoading ? (
              plans?.map((row, index) => (
                <tr key={index} className="[&>*]:py-4 text-center [&>*]:px-3">
                  <td>{index + 1}</td>
                  <td>{row?.name}</td>
                  <td>
                    <div
                      className="grid justify-center cursor-pointer"
                      onClick={() =>
                        navigate("/plans/edit", { state: { id: row?.id } })
                      }
                    >
                      <RadioButton id="isDefault" boolValue={row?.isDefault} />
                    </div>
                  </td>
                  <td>
                    <div
                      className="grid justify-center cursor-pointer"
                      onClick={() =>
                        navigate("/plans/edit", { state: { id: row?.id } })
                      }
                    >
                      <RadioButton
                        id="isRecommended"
                        boolValue={row?.isRecommended}
                      />
                    </div>
                  </td>
                  <td className="flex justify-center items-center gap-1">
                    <RiPencilLine
                      onClick={() =>
                        navigate("/plans/edit", { state: { id: row?.id } })
                      }
                      className="cursor-pointer"
                      size={15}
                    />
                    <RiDeleteBinLine
                      size={16}
                      className="text-red-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeletePlan(row?.id);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="grid w-full justify-center text-primary-500 p-2">
                    <div className="loading loading-sm" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!isLoading && !plans?.length && (
          <div className="grid w-full text-gray-400 italic justify-center text-xs">
            No Data Found
          </div>
        )}
      </div>
      <ModalComponent
        visibility={isDeleteModalOpen?.status}
        onChange={() => handleDeletePlan(null)}
      >
        <div className="grid gap-2">
          <div className="grid gap-2">
            <div className="bg-secondary-500/10 dark:bg-secondary-500/10 p-4 py-3 rounded-md rounded-b-none rounded-md rounded-b-none">
              <div className="font-bold dark:text-darkModeText">
                Delete Plan
              </div>
            </div>
            <div className="text-sm text-gray-500  p-4 ">
              Are You Sure to Delete This?
            </div>
          </div>
          <div className="flex w-full justify-end gap-3  p-2 py-2">
            <div className="w-1/6">
              <Button className="w-full" onClick={() => handleDeletePlan(null)}>
                No
              </Button>
            </div>
            <div className="w-1/6">
              <Button
                className="w-full"
                type="primary"
                onClick={handleSubmitDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default Plans;
