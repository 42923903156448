import { useState } from "react";

import DatePickerField from "../../../features/ui/elements/DatePickerFIeld";
import Button from "../../../features/ui/elements/Button";
import SelectField from "../../../features/ui/elements/SelectField";
import TextField from "../../../features/ui/elements/TextField";
import { addCoupon, getSingleCoupon, updateCoupon } from "../../../api/coupons";
import ReactDatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";

const discountTypes = [
  { label: "Flat", value: "flat" },
  { label: "Percentage", value: "percentage" },
];

const couponForList = [
  { label: "Subscription", value: "subscription" },
  { label: "Addon", value: "addon" },
];

const AddCoupon = () => {
  const [coupon, setCoupon] = useState({
    name: null,
    description: null,
    couponCode: null,
    discountValue: null,
    discountType: null,
    usageLimit: null,
    minimumAmount: null,
    maxDiscountPrice: null,
    couponFor: null,
    validFrom: new Date(),
    validUntil: new Date(),
  });

  const { state } = useLocation();
  const { id } = state;

  const navigate = useNavigate();

  const fetchData = async () => {
    if (id) {
      const data = await getSingleCoupon(id);
      if (data) {
        setCoupon({
          name: data?.name,
          description: data?.description,
          couponCode: data?.couponCode,
          couponFor: data?.couponFor,
          discountValue: data?.discountValue,
          discountType: data?.discountType,
          usageLimit: data?.usageLimit,
          minimumAmount: data?.minimumAmount,
          maxDiscountPrice: data?.maxDiscountPrice,
          validFrom: new Date(),
          validUntil: new Date(),
          validFrom: new Date(data?.validFrom),
          validUntil: new Date(data?.validUntil),
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("name ", name);
    setCoupon({ ...coupon, [name]: value });
  };

  const handleAddCoupon = async () => {
    let data;
    if (id) {
      data = await updateCoupon(id, coupon);
    } else {
      data = await addCoupon(coupon);
    }
    if (data) {
      navigate("/coupons");
    }
  };

  return (
    <div className="grid gap-6 p-4">
      <h2 className="text-2xl font-extrabold dark:text-darkModeText">
        {id ? "Edit" : "Add"} Coupon
      </h2>
      <div className="space-y-3">
        <div className="grid gap-3 grid-cols-2">
          <TextField
            id="name"
            placeholder="Coupon Name"
            value={coupon?.name}
            onChange={onChange}
            name="name"
          />
          <TextField
            id="couponCode"
            placeholder="Coupon Code"
            value={coupon?.couponCode}
            onChange={onChange}
            name="couponCode"
          />
        </div>
        <div>
          <TextField
            id="description"
            placeholder="Description"
            multiline
            value={coupon?.description}
            onChange={onChange}
            name="description"
          />
        </div>
        <div className="grid gap-3 grid-cols-2">
          <SelectField
            name="discountType"
            id="discountType"
            options={discountTypes}
            value={coupon?.discountType}
            onChange={onChange}
            placeholder={"Discount Type"}
          />
          <TextField
            id="discountValue"
            placeholder="Discount Value"
            value={coupon?.discountValue}
            onChange={onChange}
            name="discountValue"
          />
        </div>
        <div className="grid md:grid-cols-6 gap-4 md:gap-2">
          <div className="col-span-3">
            {/* <ReactDatePicker
              selected={coupon?.validFrom}
              onChange={(date) => setCoupon({ ...coupon, validFrom: date })}
              name={"validFrom"}
              disabled={false}
              className=" disabled:bg-white "
            /> */}
            <DatePickerField
              value={coupon?.validFrom}
              name="validFrom"
              id="validFrom"
              onChange={(date) => setCoupon({ ...coupon, validFrom: date })}
              placeholder={"Valid From"}
            />
          </div>

          <div className="col-span-3">
            <DatePickerField
              value={coupon?.validUntil}
              id="validUntil"
              name="validUntil"
              onChange={(date) => setCoupon({ ...coupon, validUntil: date })}
              placeholder={"Valid Until"}
            />
          </div>
        </div>
        <div className="grid gap-3 grid-cols-2">
          <TextField
            id="minimumAmount"
            placeholder="Minimum Amount"
            value={coupon?.minimumAmount}
            onChange={onChange}
            name="minimumAmount"
          />
          <TextField
            id="maxDiscountPrice"
            placeholder="Maximum Discount Amount"
            value={coupon?.maxDiscountPrice}
            onChange={onChange}
            name="maxDiscountPrice"
          />
        </div>
        <div className="grid gap-3 grid-cols-2">
          <SelectField
            name="couponFor"
            id="couponFor"
            options={couponForList}
            value={coupon?.couponFor}
            onChange={onChange}
            placeholder={"Coupon For"}
          />
          <TextField
            id="usageLimit"
            placeholder="Usage Limit"
            value={coupon?.usageLimit}
            onChange={onChange}
            name="usageLimit"
          />
        </div>
        <div>
          <Button type="primary" className="w-full" onClick={handleAddCoupon}>
            {id ? "Update" : "Add"} Coupon
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddCoupon;
